body {
    background-color: #202020;
    margin: 0;
}

.product {
    color: white;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    background-size: cover;
    line-height: 150%;
}

.product .content {
    width: 100%;
    padding: 50px;
}

.product h1 {
    font-size: 24px;
    height: 52px;
    line-height: 28px;
    text-align: right;
    margin-bottom: 0;
    font-family: "Space Grotesk", sans-serif
}

.product h2 {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
}

.product div.tutorial {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product div.tutorial a {
    padding: 18px 8px 8px 8px;
    margin: 7px;
    background-color: white;
    text-align: center;
    position: relative;
    border-radius: 25px;
}

@media (max-width: 1000px) {
    .product div.tutorial a {}
}

.product .tutorial img {
    height: 180px;
    width: 320px;
}

.product .language,
.product .language a {
    font-size: 80%;
}

.product div.language>button {
    color: white;
}

.product div.language>button.MuiToggleButton-root.Mui-selected {
    color: gray;
}